<template>
  <v-app
    style="background: #E3F2FD;
        background: linear-gradient(to bottom, #1976D2, #1E88E5, #E3F2FD);"
    fixed-tabs
  >
    <v-layout row wrap align-center justify-center>
      <v-flex xs12 lg7 class="ma-5">
        <v-card color="white" v-if="checkregistersuccess == false">
          <v-card-text class="pa-0">
            <v-form v-model="valid" class="ma-4 mt-4 mr-4 ml-4">
              <v-container fluid>
                <v-layout row wrap justify-center>
                  <v-flex xs12 sm12 md12 lg12>
                    <v-card-text style="font-size: 20px"
                    >ลงทะเบียนผู้ใช้งาน One Platform<br>
                    <p style="font-size: 17px">(Register Account One Platform)</p>
                    </v-card-text>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg4 class="mt-2">
                    <v-select
                      outlined
                      dense
                      clearable
                      class="pa-1 pt-4 pb-0"
                      v-model="typename_th"
                      :items="listtypenameth"
                      item-value="data_type"
                      item-text="data_type"
                      label="คำนำหน้าชื่อ (ไทย) / Title Name (TH)"
                      prepend-icon="mdi-account-circle"
                      :rules="[rules.noSpecialChar]"
                      :error-messages="typename_thError"
                      required
                      @input="fn_checktypenameth(), $v.typename_th.$touch()"
                      @blur="$v.typename_th.$touch()"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg4 class="mt-2">
                    <v-text-field
                      clearable
                      outlined
                      dense
                      class="pa-1 pt-4 pb-0"
                      v-model="firstnameth"
                      label="ชื่อ (ไทย) / First Name (TH)"
                      prepend-icon="mdi-account-circle"
                      :rules="requiredname"
                      :error-messages="firstnamethError"
                      required
                      @change="$v.firstnameth.$touch()"
                      @blur="$v.firstnameth.$touch()"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg4 class="mt-2">
                    <v-text-field
                      clearable
                      outlined
                      dense
                      class="pa-1 pt-4 pb-0"
                      v-model="lastnameth"
                      label="นามสกุล (ไทย) / Last Name (TH)"
                      prepend-icon="mdi-account-circle"
                      :rules="requiredname"
                      :error-messages="lastnamethError"
                      required
                      @input="$v.lastnameth.$touch()"
                      @blur="$v.lastnameth.$touch()"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center class="mt-2">
                  <v-flex xs12 sm12 md12 lg4>
                    <v-select
                      outlined
                      dense
                      clearable
                      v-model="typename_eng"
                      :items="listtypenameeng"
                      item-value="data_type"
                      item-text="data_type"
                      label="คำนำหน้าชื่อ (อังกฤษ) / Title Name (Eng)"
                      prepend-icon="mdi-account-circle"
                      :rules="[rules.noSpecialChar]"
                      :error-messages="typename_engError"
                      required
                      @input="fn_checktypenameeng(), $v.typename_eng.$touch()"
                      @blur="$v.typename_eng.$touch()"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg4>
                    <v-text-field
                      clearable
                      outlined
                      dense
                      class="pa-1 pt-0 pb-0"
                      v-model="firstnameeng"
                      label="ชื่อ (อังกฤษ) / First Name (Eng)"
                      prepend-icon="mdi-account-circle"
                      :rules="requirednameeng"
                      :error-messages="firstnameengError"
                      required
                      @input="$v.firstnameeng.$touch()"
                      @blur="$v.firstnameeng.$touch()"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg4>
                    <v-text-field
                      clearable
                      outlined
                      dense
                      class="pa-1 pt-0 pb-0"
                      v-model="lastnameeng"
                      label="นามสกุล (อังกฤษ) / Last Name (Eng)"
                      prepend-icon="mdi-account-circle"
                      :rules="requirednameeng"
                      :error-messages="lastnameengError"
                      required
                      @input="$v.lastnameeng.$touch()"
                      @blur="$v.lastnameeng.$touch()"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center class="mt-2">
                  <v-flex xs12 sm12 md12 lg4>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          outlined
                          dense
                          label="วันเกิด / Date of Birth"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                          <!-- required
                          :rules="[() => !!date || 'กรุณากรอกข้อมูล']"
                          :error-messages="dateError"
                          @change="$v.date.$touch()"
                          @blur="$v.date.$touch()" -->
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="date"
                        :max="new Date().toISOString().substr(0, 10)"
                        min="1900-01-01"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg4>
                    <v-select
                      class="pa-1 pt-0 pb-0"
                      outlined
                      dense
                      clearable
                      v-model="typecrad"
                      :items="listtypecrad"
                      item-value="listtypecrad_value"
                      item-text="listtypecrad_type"
                      label="ชนิดบัตร / Type"
                      prepend-icon="credit_card"
                      :rules="[rules.noSpecialChar]"
                      :error-messages="typecradError"
                      required
                      @input="$v.typecrad.$touch()"
                      @blur="$v.typecrad.$touch()"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg4>
                    <v-text-field
                      clearable
                      outlined
                      dense
                      :disabled="typecrad === ''"
                      class="pa-1 pt-0 pb-0"
                      v-model="typecrad_num"
                      :label="typecrad === 'หนังสือเดินทาง'? 'หมายเลขหนังสือเดินทาง / Passport Number' : 'รหัสบัตร 13 หลัก / Identification Number'"
                      :maxlength="typecrad === 'หนังสือเดินทาง' ? 20 : 13"
                      prepend-icon="credit_card"
                      :rules="typecrad === 'หนังสือเดินทาง'? required_ : requiredidcard  "
                      :error-messages="typecrad_numError"
                      required
                      @input="$v.typecrad_num.$touch()"
                      @blur="$v.typecrad_num.$touch()"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-left class="mt-2">
                  <v-flex xs12 sm6 md6 lg4>
                    <v-text-field
                      clearable
                      outlined
                      dense
                      v-model="email"
                      label="อีเมล / E-mail"
                      prepend-icon="email"
                      :rules="requiredemail"
                      required
                      :error-messages="emailError"
                      @input="$v.email.$touch()"
                      @blur="$v.email.$touch()"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg4>
                    <v-text-field
                      clearable
                      outlined
                      dense
                      class="pa-1 pt-0 pb-0"
                      v-model="phonenumber"
                      label="เบอร์โทรศัพท์ / Mobile No."
                      maxlength="10"
                      prepend-icon="phone"
                      :rules="requiredTextphone"
                      required
                      :error-messages="phonenumberError"
                      @input="$v.phonenumber.$touch()"
                      @blur="$v.phonenumber.$touch()"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center class="mt-2">
                  <v-flex xs12 sm4 md4 lg4>
                    <v-text-field
                      clearable
                      outlined
                      dense
                      class="pa-1 pt-0 pb-0"
                      v-model="username"
                      label="ชื่อผู้ใช้ / Username"
                      prepend-icon="people"
                      :rules="requiredusername"
                      required
                      :error-messages="usernameError"
                      @input=" fn_tolowercase(),$v.username.$touch()"
                      @blur="$v.username.$touch()"
                      hint="ตัวอักษรอย่างน้อย 6 ตัว และห้ามมีอักขระพิเศษ / Must have at least 6 characters"
                      persistent-hint
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4 lg4>
                    <v-text-field
                      clearable
                      outlined
                      dense
                      class="pa-1 pt-0 pb-0"
                      v-model="password"
                      label="รหัสผ่าน / Password"
                      prepend-icon="vpn_key"
                      @click:append="showpassword = !showpassword"
                      :type="showpassword ? 'text' : 'password'"
                      :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="requiredpassword"
                      :error-messages="passwordError"
                      required
                      @input="$v.password.$touch()"
                      @blur="$v.password.$touch()"
                      hint="ต้องมีตัวเลข และตัวอักษรภาษาอังกฤษ อย่างน้อย 1 ตัว ความยาวอย่างน้อย 8 / Must have at least 8 characters"
                      persistent-hint
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4 lg4>
                    <v-text-field
                      clearable
                      outlined
                      dense
                      class="pa-1 pt-0 pb-0"
                      v-model="confirmpassword"
                      label="ยืนยันรหัสผ่าน / ConfirmPassword"
                      prepend-icon="vpn_key"
                      @click:append="showpassword = !showpassword"
                      :type="showpassword ? 'text' : 'password'"
                      :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="requiredconfirmpassword"
                      :error-messages="confirmpasswordError"
                      required
                      @input="$v.confirmpassword.$touch()"
                      @blur="$v.confirmpassword.$touch()"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center>
                    <v-checkbox v-model="check_accept" color="primary" class="text-center">
                      <template v-slot:label>
                        <v-card-text class="pa-0 pt-4" @click.stop="">
                          ยอมรับ
                          <a @click.prevent="open()">ข้อกำหนดการใช้บริการ ONE ID</a>
                          และ
                          <a @click.prevent="opendialogpolicyoneid = true">นโยบายความคุ้มครองข้อมูลส่วนบุคคล</a><br>
                          <p>(Accept Terms Of Service ONE ID And Privacy Policy)</p>
                        </v-card-text>
                      </template>
                    </v-checkbox>
                </v-layout>
                <v-divider></v-divider>
                <v-layout row wrap justify-center class="mt-2">
                  <v-flex class="text-center">
                    <v-btn large color="primary" outlined :disabled="createprogress" @click="$router.push('/')">กลับหน้าหลัก<br>(HOME)</v-btn>
                    <v-btn large class="ml-2" color="primary" :loading="createprogress" :disabled="!check_accept" @click="fn_register()"
                    >ลงทะเบียน<br>(Register)</v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-text>
            <v-form v-model="valid">
              <v-container fluid>
                <v-layout row wrap justify-center>
                  <v-flex xs12 sm12 md12 lg12 class="text-center">
                    <v-icon size="200px" color="primary">mdi-check-circle-outline</v-icon>
                    <br /><br />
                    <h1>ลงทะเบียนสำเร็จ</h1><br>
                    <h3 style="color:#00C853;">(Register One Platform Successfully)</h3>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center>
                  <v-flex v-if="resolutionScreen >=400" xs5 sm5 md5 lg5>
                    <v-card lass="mx-auto" max-width="500" max-height="200" elevation="0">
                      <v-card-text>
                        <p style="text-align: center;"><strong>ข้อมูล</strong></p>
                        <p style="text-align: center; color:red;">โปรดจำข้อมูลนี้ !!</p>
                        <p style="padding-left: 50px; text-align: left;"><strong>username :</strong> {{ username }}</p>
                        <p style="padding-left: 50px; text-align: left;"><strong>อีเมล :</strong> {{ email }}</p>
                        <p style="padding-left: 50px; text-align: left;"><strong>เบอร์โทร :</strong> {{ phonenumber }}</p>
                      </v-card-text>
                      <br />
                    </v-card>
                  </v-flex>
                  <v-flex v-else xs10 sm10 md10 lg10>
                    <br />
                    <p style="text-align: center;"><strong>ข้อมูล</strong></p>
                    <p style="text-align: center; color:red;">โปรดจำข้อมูลนี้ !!</p>
                    <p style="padding-left: 50px; text-align: left;"><strong>username :</strong> {{ username }}</p>
                    <p style="padding-left: 50px; text-align: left;"><strong>อีเมล :</strong> {{ email }}</p>
                    <p style="padding-left: 50px; text-align: left;"><strong>เบอร์โทร :</strong> {{ phonenumber }}</p>
                    <br />
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center class="mt-10">
                  <v-flex class="text-center">
                    <v-btn large color="primary" outlined @click="$router.push('/')">กลับหน้าหลัก<br>(HOME)</v-btn>
                    <v-btn large class="ml-2" color="primary" @click="$router.push('/loginbysso')">เข้าสู่ระบบ<br>(Log in)</v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
        <dialogoneid :show="opendialogoneid" @closedialog="opendialogoneid = false"></dialogoneid>
        <dialogpolicyoneid :show="opendialogpolicyoneid" @closedialog="opendialogpolicyoneid = false"></dialogpolicyoneid>
      </v-flex>
    </v-layout>
  </v-app>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";

const dialogoneid = () => import("../components/optional/dialog-dialogoneid.vue");
const dialogpolicyoneid = () => import("../components/optional/dialog-dialogpolicyoneid.vue");
// import gbfGenerate from "@/globalFunctions/generateAuthorize";
// import axios from "axios";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  mixins: [validationMixin],
  validations: {
    typename_th: { required },
    firstnameth: { required },
    lastnameth: { required },
    typename_eng: { required },
    firstnameeng: { required },
    lastnameeng: { required },
    typecrad: { required },
    typecrad_num: { required },
    email: { required },
    username: { required },
    confirmpassword: { required },
    password: { required },
    phonenumber: { required },
    // date: { required },
  },
  components: {
    dialogoneid,
    dialogpolicyoneid,
  },
  data: function() {
    return {
      allowcheckpassword:"",
      allowcheckuser: "",
      check_accept: false,
      opendialogoneid: false,
      opendialogpolicyoneid: false,
      checkbox: false,
      checkregistersuccess: false,
      createprogress: false,
      valid: "",
      typename_th: "",
      firstnameth: "",
      lastnameth: "",
      typename_eng: "",
      firstnameeng: "",
      lastnameeng: "",
      typecrad: "",
      typecrad_num: "",
      email: "",
      username: "",
      confirmpassword: "",
      password: "",
      phonenumber: "",
      showpassword: false,
      listtypenameth: ["นาย", "นาง", "นางสาว", "พระครู"],
      listtypenameeng: ["Mr", "Mrs", "Miss", "PHRAKHU"],
      listtypecrad: [
        {
          listtypecrad_type: "บัตรประชาชน (ID Card)",
          listtypecrad_value: "บัตรประชาชน",
        },
        {
          listtypecrad_type: "หนังสือเดินทาง (Passport)",
          listtypecrad_value: "หนังสือเดินทาง",
        },],
      rules: {
        noSpecialChar: (value) => !!value || "โปรดระบุข้อมูล.",
      },
      required_:[
        (value) => !!value || "โปรดระบุข้อมูล.",
      ],
      requiredTextphone: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) => (value && value.length >= 10 && this.fn_checkphonenumber(value)) || "โปรดระบุตัวเลข 10 หลัก / Please fill 10 digits",
      ],
      requiredidcard: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) => (value && value.length >= 13 && this.fn_checkidcard(value)) || "โปรดระบุตัวเลข 13 หลัก / Please fill 13 digits",
      ],
      requiredemail: [(value) => !!value || "โปรดระบุข้อมูล.", (value) => /.+@.+\..+/.test(value) || "ข้อมูลไม่ถูกต้อง / Please fill Email"],
      // && this.fn_checkSpecialChar(value)
     requiredusername: [
      (value) => 
        (/^(?=.*[a-z])/.test(value) && value.length >= 6 && this.fn_checkusername(value)) || "ตัวอักษรอย่างน้อย 6 ตัว และห้ามมีอักขระพิเศษ / Must have at least 6 characters",
        (value) => !!value || "โปรดระบุข้อมูล.",
      ],
      requiredpassword: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          (/^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(value) && this.fn_checkSpecialChar_(value)) ||
          "ต้องมีตัวเลข และตัวอักษรภาษาอังกฤษ อย่างน้อย 1 ตัว ความยาวอย่างน้อย 8 / Must have at least 8 characters",
      ],
      requiredconfirmpassword: [(value) => !!value || "โปรดระบุข้อมูล.", (value) => this.checkpass(value) || "รหัสผ่านไม่ตรงกัน / Passwords do not match"],
      requiredname: [(value) => !!value || "โปรดระบุข้อมูล.", (value) => this.checkname(value) || "ข้อมูลไม่ถูกต้อง / Invalid data"],
      requirednameeng: [(value) => !!value || "โปรดระบุข้อมูล.", (value) => this.checknameeng(value) || "ข้อมูลไม่ถูกต้อง / Invalid data"],
      // birthdaydate: null,
      date: null,
      menu: false,
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  computed: {
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    typename_thError() {
      const errors = [];
      if (!this.$v.typename_th.$dirty) return errors;
      !this.$v.typename_th.required && errors.push("โปรดระบุคำนำหน้าชื่อ(ภาษาไทย) / Please fill Title Name (TH)");
      return errors;
    },
    firstnamethError() {
      const errors = [];
      if (!this.$v.firstnameth.$dirty) return errors;
      !this.$v.firstnameth.required && errors.push("โปรดระบุชื่อ(ภาษาไทย) / Please fill First Name (TH)");
      return errors;
    },
    lastnamethError() {
      const errors = [];
      if (!this.$v.lastnameth.$dirty) return errors;
      !this.$v.lastnameth.required && errors.push("โปรดระบุนามสกุล(ภาษาไทย) / Please fill Last Name (TH)");
      return errors;
    },
    typename_engError() {
      const errors = [];
      if (!this.$v.typename_eng.$dirty) return errors;
      !this.$v.typename_eng.required && errors.push("โปรดระบุคำนำหน้าชื่อ(ภาษาอังกฤษ) / Please fill Title Name (Eng)");
      return errors;
    },
    firstnameengError() {
      const errors = [];
      if (!this.$v.firstnameeng.$dirty) return errors;
      !this.$v.firstnameeng.required && errors.push("โปรดระบุชื่อ(ภาษาอังกฤษ) / Please fill First Name (Eng)");
      return errors;
    },
    lastnameengError() {
      const errors = [];
      if (!this.$v.lastnameeng.$dirty) return errors;
      !this.$v.lastnameeng.required && errors.push("โปรดระบุนามสกุล(ภาษอังกฤษ) / Please fill Last Name (Eng)");
      return errors;
    },
    typecradError() {
      const errors = [];
      if (!this.$v.typecrad.$dirty) return errors;
      !this.$v.typecrad.required && errors.push("โปรดระบุชนิดบัตร / Please fill Type Card");
      return errors;
    },
    typecrad_numError() {
      const errors = [];
      if (!this.$v.typecrad_num.$dirty) return errors;
      !this.$v.typecrad_num.required && errors.push("โปรดระบุข้อมูล / Please fill Type Card Number");
      return errors;
    },
    emailError() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("โปรดระบุอีเมล / Please fill Email");
      return errors;
    },
    phonenumberError() {
      const errors = [];
      if (!this.$v.phonenumber.$dirty) return errors;
      !this.$v.phonenumber.required && errors.push("โปรดระบุเบอร์โทรศัพท์ / Please fill Mobile No.");
      return errors;
    },
    dateError() {
      const errors = [];
      if (!this.$v.date.$dirty) return errors;
      !this.$v.date.required && errors.push("โปรดระบุข้อมูลวันเกิด / Please fill Date of Birth");
      return errors;
    },
    usernameError() {
      const errors = [];
      if (!this.$v.username.$dirty) return errors;
      !this.$v.username.required && errors.push("โปรดระบุข้อมูลชื่อผู้ใช้ / Please fill Username");
      return errors;
    },
    passwordError() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("โปรดระบุรหัสผ่าน / Please fill Password / Must have at least 8 characters");
      return errors;
    },
    confirmpasswordError() {
      const errors = [];
      if (!this.$v.confirmpassword.$dirty) return errors;
      !this.$v.confirmpassword.required && errors.push("โปรดยืนยันรหัสผ่าน / Please fill Password / Must have at least 8 characters");
      return errors;
    },
  },
  methods: {
    fn_opendialog1() {},
    save(date) {
      this.$refs.menu.save(date);
    },
    checkpass(value) {
      if (value === this.password) {
        return true;
      } else {
        return false;
      }
    },
    fn_checkSpecialChar(value) {
      let allow = true;
      let specialChar = [" ", "!", "#", "^", "&", "(", ")", "+", "$", "%", "<", ">", ":", "*", "?", "\\", "|", "/", '"',"'"];

      for (let index = 0; index < specialChar.length; index++) {
        const element = specialChar[index];
        if (value.includes(element)) {
          allow = false;
          break;
        }
      }
      // console.log("allow", allow);
      return allow;
    },
    fn_checkSpecialChar_(value){
      let allow = true;
      let specialChar = [" ", '"',"'"];

      for (let index = 0; index < specialChar.length; index++) {
        const element = specialChar[index];
        if (value.includes(element)) {
          allow = false;
          break;
        }
      }
      this.allowcheckpassword = allow;
      console.log("allow", allow);
      return allow;
    },
    fn_checkidcard(value) {
      const pattern = /^[0-9]{1,}$/i;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    fn_checkphonenumber(value) {
      const pattern = /^0[0-9]{8,9}$/i;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    checkname(value) {
      const pattern = /^[ก-๏\s]+$/;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    fn_checkusername(value){
      console.log("value fn_checkusername",value);
      // const pattern = /^[a-z0-9]+$/;
      const pattern = /^[a-z0-9]*$/;
      //const pattern =  /^(?=.*[a-z])(?=.*[0-9]){8,}/;
      if (pattern.test(value) == true) {
        console.log("checkusername true");
        this.allowcheckuser = true;
         console.log(this.allowcheckuser);
        return true;
      } else {
        console.log("checkusername false");
        this.allowcheckuser = false;
        console.log(this.allowcheckuser);
        return false;
      }
    },
    checknameeng(value) {
      const pattern = /^[a-zA-Z\s]+$/;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    fn_register() {
      this.$v.$touch();
      // let allowcheckusername = this.fn_checkusername();
      if (!this.$v.$invalid) {
        if(this.password !== this.confirmpassword){
          Toast.fire({
            icon: "error",
              title: "รหัสผ่านไม่ตรงกัน กรุณายืนยันรหัสผ่านอีกครั้ง",
            });
          this.password = '';
          this.confirmpassword = '';
        }else if(this.allowcheckuser == false){ 
          Toast.fire({
            icon: "error",
              title: "รูปแบบชื่อผู้ใช้งานไม่ถูกต้อง",
            });
            // this.username = '';
        }else if(this.allowcheckpassword == false){
          Toast.fire({
            icon: "error",
              title: "รูปแบบรหัสผ่านไม่ถูกต้อง",
            });
        }else{
          console.log("fn_checkusername",this.fn_checkusername);
        this.createprogress = true;
        let payload = {
          account_title_th: this.typename_th,
          first_name_th: this.firstnameth,
          last_name_th: this.lastnameth,
          account_title_eng: this.typename_eng,
          first_name_eng: this.firstnameeng,
          last_name_eng: this.lastnameeng,
          id_card_type: this.typecrad === "บัตรประชาชน" ? "idcard" : this.typecrad === "หนังสือเดินทาง" ? "passport" : "",
          id_card_num: this.typecrad_num,
          email: this.email,
          mobile_no: this.phonenumber,
          birth_date: this.date,
          username: this.username,
          password: this.password,
        };
        console.log("payload", payload);
        // let auth = await gbfGenerate.generateToken();
        // console.log("auth",auth);
        this.axios
          .post(process.env.VUE_APP_SERVICE_REGISTER + "/api/register_account", payload, {
            headers: {
              Authorization:
                `Bearer ${process.env.VUE_APP_BEARER_TOKEN}`,
            },
          })
          .then((response) => {
            console.log(response);
            if (response.data.status === "OK") {
              this.createprogress = false;
              console.log("response ok", response);
              Toast.fire({
                icon: "success",
                title: this.$t("register.regissuccess"),
              });
              this.checkregistersuccess = true;
              // this.fn_cleardata();
              // this.$router.push("/login");
            } else {
              this.createprogress = false;
              console.log("response notok", response);
              console.log("response notok", response.data.errorMessage);
              Toast.fire({
                icon: "error",
                title:
                  response.data.errorMessage.id_card_num ||
                  response.data.errorMessage.first_name_th ||
                  response.data.errorMessage.last_name_th ||
                  response.data.errorMessage.first_name_eng || response.data.errorMessage.last_name_eng ||
                  response.data.errorMessage.email ||
                  response.data.errorMessage.mobile_no ||
                  response.data.errorMessage.birth_date ||
                  response.data.errorMessage.username ||
                  response.data.errorMessage.password || 
                  response.data.errorMessage.id_card_type || 
                  response.data.errorMessage,
              });
                  if(response.data.errorMessage.id_card_num){
                    this.typecrad_num = ''
                  }else if(response.data.errorMessage.first_name_th){
                    this.firstnameth = ''
                  }else if(response.data.errorMessage.last_name_th){
                    this.lastnameth = ''
                  }else if(response.data.errorMessage.first_name_eng){
                    this.firstnameeng = ''
                  }else if(response.data.errorMessage.last_name_eng){
                    this.lastnameeng = ''
                  }
                  if(response.data.errorMessage.email){
                    this.email = ''
                  }else if(response.data.errorMessage.mobile_no){
                    this.phonenumber = ''
                  }else if(response.data.errorMessage.birth_date){
                    this.date = ''
                  }else if(response.data.errorMessage.username){
                    this.username = ''
                  }else if(response.data.errorMessage.password){
                    this.password = ''
                  } 
            }
          })
          .catch((error) => {
            this.createprogress = false;
            Toast.fire({
              icon: "error",
              title: error,
            });
            console.log(error);
          });
        }
      }
    },
    fn_cleardata() {
      this.typename_th = "";
      this.firstnameth = "";
      this.lastnameth = "";
      this.typename_eng = "";
      this.firstnameeng = "";
      this.lastnameeng = "";
      this.typecrad = "";
      this.typecrad_num = "";
      this.email = "";
      this.phonenumber = "";
      this.date = "";
      this.username = "";
      this.password = "";
    },
    fn_checktypenameth() {
      if (this.typename_th === "นาย") {
        this.typename_eng = "Mr";
      } else if (this.typename_th === "นาง") {
        this.typename_eng = "Mrs";
      } else if (this.typename_th === "นางสาว") {
        this.typename_eng = "Miss";
      }
    },
    fn_checktypenameeng() {
      if (this.typename_eng === "Mr") {
        this.typename_th = "นาย";
      } else if (this.typename_eng === "Mrs") {
        this.typename_th = "นาง";
      } else if (this.typename_eng === "Miss") {
        this.typename_th = "นางสาว";
      }
    },
    open() {
      this.opendialogoneid = true;
    },
    fn_tolowercase(){
       this.username = this.username.toLowerCase()
    }
  },
};
</script>
<style lang="less" scoped></style>
